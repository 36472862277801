import { nanoid } from "nanoid"
import Logger from "@/utils/Logger"

export async function useJobShopData() {
  const { $api } = useNuxtApp()
  const { t } = useI18n()
  const { tenantId, jobShopId, acceptLanguage } = useHeaderStore()
  const { dataCacheTtl } = useRuntimeConfig()

  const requestId = nanoid()
  const contextString = `JobShop data for tenantId: "${tenantId}" and jobShopId: "${jobShopId}"`

  let startTime
  if (import.meta.server) {
    Logger(requestId).start(`Start fetching ${contextString}`)
    startTime = Date.now()
  }

  // Using the useCachedAsyncData composable from nuxt-multi-cache here and adding relevant tags for cache invalidation
  const {
    data: jobShop,
    status: jobShopStatus,
    error: jobShopError,
  } = await useCachedAsyncData(`jobShopData`, () => $api.jobShop.getJobShopData(tenantId, jobShopId, acceptLanguage), {
    serverMaxAge: dataCacheTtl,
    serverCacheTags: [
      `tenantId:${tenantId}`,
      `jobShopId:${jobShopId}`,
    ],
  })

  if (import.meta.server) {
    if (!jobShopError.value) {
      const duration = Date.now() - startTime
      Logger(requestId).success(`Successfully fetched ${contextString}. (${duration}ms)`)
    }
    else {
      const errorMessage = jobShopError.value?.data?.errors[0]?.message || jobShopError.value?.data?.description || "Unknown error occurred"
      Logger(requestId).error(`Error while fetching ${contextString}: ${errorMessage}\n\n`, jobShopError.value)
    }
  }

  if (jobShopError.value) {
    throw createError({
      statusCode: jobShopError.value.statusCode,
      statusMessage: t("error-messages.general"),
    })
  }

  return { jobShop, jobShopStatus, jobShopError }
}
