export function useHeadingFontClass(jobShop: JobShop | null) {
  const fontClass = computed(() => {
    const fontVariant = jobShop?.["fonts-and-colors"]?.config?.general?.fontAdjustments?.fontSizeVariant?.value?.toLowerCase() || "large"

    return `use-${fontVariant}-headings`
  })

  useHead({
    bodyAttrs: {
      class: fontClass.value,
    },
  })
}
